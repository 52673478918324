import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { api } from "../services/apiService";
import type from "../actions/types";

function* getCategories() {
    try {
        const categoriesRes = yield call(api.getCategories);
        if (!categoriesRes) {
            yield put({
                type: type.GET_CATEGORIES_ERROR,
                status: categoriesRes.errStatus,
            });
        } else {
            yield put({
                type: type.GET_CATEGORIES_SUCCESS,
                categoriesList: categoriesRes,
            });
        }
    } catch (e) {
        yield put({ type: type.GET_CATEGORIES_ERROR, message: e.message });
    }
}

function* getQuesCategories() {
    try {
        const categoriesRes = yield call(api.getQuesCategories);
        if (!categoriesRes.result) {
            yield put({
                type: type.GET_QUES_CATEGORIES_ERROR,
                status: categoriesRes.errStatus,
            });
        } else {
            yield put({
                type: type.GET_QUES_CATEGORIES_SUCCESS,
                quesCategoriesList: categoriesRes,
            });
        }
    } catch (e) {
        yield put({ type: type.GET_QUES_CATEGORIES_ERROR, message: e.message });
    }
}

function* createQuiz(action) {
    try {
        const quizRes = yield call(api.createQuiz, action.payload);
        if (quizRes.errStatus) {
            const msg = 'Cannot create quiz for some error...';
            yield put({ type: type.CREATE_QUIZ_ERROR, status: quizRes?.errStatus, errMsg: quizRes?.errMsg?.message || msg });
        } else {
            yield put({ type: type.CREATE_QUIZ_SUCCESS, createQuiz: quizRes });
        }
    } catch (e) {
        yield put({ type: type.CREATE_QUIZ_ERROR, message: e.message });
    }
}

function* getQuiz(action) {
    try {
        const quizDetailsRes = yield call(api.getQuiz, action.payload);
        if (quizDetailsRes.errStatus) {
            yield put({ type: type.GET_QUIZ_ERROR, status: quizDetailsRes.errStatus });
        } else {
            yield put({ type: type.GET_QUIZ_SUCCESS, quizDetails: quizDetailsRes });
        }
    } catch (e) {
        yield put({ type: type.GET_QUIZ_ERROR, message: e.message });
    }
}

function* submitQuiz(action) {
    try {
        const submitQuizRes = yield call(api.submitQuiz, action.payload);
        if (submitQuizRes.errStatus) {
            yield put({ type: type.SUBMIT_QUIZ_ERROR, status: submitQuizRes.errStatus });
        } else {
            yield put({ type: type.SUBMIT_QUIZ_SUCCESS, submitQuiz: submitQuizRes });
        }
    } catch (e) {
        yield put({ type: type.SUBMIT_QUIZ_ERROR, message: e.message });
    }
}

function* questionHistory(action) {
    try {
        const questionHistoryRes = yield call(
            api.getQuestionHistory,
            action.payload
        );
        if (questionHistoryRes.errStatus) {
            yield put({
                type: type.GET_QUESTION_HISTORY_ERROR,
                status: questionHistoryRes.errStatus,
            });
        } else {
            yield put({
                type: type.GET_QUESTION_HISTORY_SUCCESS,
                questionHistory: questionHistoryRes,
            });
        }
    } catch (e) {
        yield put({ type: type.GET_QUESTION_HISTORY_ERROR, message: e.message });
    }
}

function* quizHistory(action) {
    try {
        const quizHistoryRes = yield call(api.getQuizHistory, action.payload);
        if (quizHistoryRes.errStatus) {
            yield put({
                type: type.GET_QUIZ_HISTORY_ERROR,
                status: quizHistoryRes.errStatus,
            });
        } else {
            yield put({
                type: type.GET_QUIZ_HISTORY_SUCCESS,
                quizHistory: quizHistoryRes,
            });
        }
    } catch (e) {
        yield put({ type: type.GET_QUIZ_HISTORY_ERROR, message: e.message });
    }
}

function* studentQuizHistory(action) {
    try {
        const quizHistoryRes = yield call(
            api.getStudentQuizHistory,
            action.payload
        );
        if (quizHistoryRes.errStatus) {
            yield put({
                type: type.GET_STUDENT_QUIZ_HISTORY_ERROR,
                status: quizHistoryRes.errStatus,
            });
        } else {
            yield put({
                type: type.GET_STUDENT_QUIZ_HISTORY_SUCCESS,
                studentQuizHistory: quizHistoryRes,
            });
        }
    } catch (e) {
        yield put({
            type: type.GET_STUDENT_QUIZ_HISTORY_ERROR,
            message: e.message,
        });
    }
}

function* overallQuizHistory(action) {
    try {
        const quizHistoryRes = yield call(
            api.getOverallQuizHistory,
            action.payload
        );
        if (quizHistoryRes.errStatus) {
            yield put({
                type: type.GET_OVERALL_QUIZ_HISTORY_ERROR,
                status: quizHistoryRes.errStatus,
            });
        } else {
            yield put({
                type: type.GET_OVERALL_QUIZ_HISTORY_SUCCESS,
                overallQuizHistory: quizHistoryRes,
            });
        }
    } catch (e) {
        yield put({
            type: type.GET_OVERALL_QUIZ_HISTORY_ERROR,
            message: e.message,
        });
    }
}

function* getOverallStudentQuiz(action) {
    try {
        const quizHistoryRes = yield call(
            api.getOverallStudentQuiz,
            action.payload
        );
        if (quizHistoryRes.errStatus) {
            yield put({
                type: type.GET_OVERALL_STUDENT_QUIZ_HISTORY_ERROR,
                status: quizHistoryRes.errStatus,
            });
        } else {
            yield put({
                type: type.GET_OVERALL_STUDENT_QUIZ_HISTORY_SUCCESS,
                overallStudentQuiz: quizHistoryRes,
            });
        }
    } catch (e) {
        yield put({
            type: type.GET_OVERALL_STUDENT_QUIZ_HISTORY_ERROR,
            message: e.message,
        });
    }
}

function* getQuestionMetadata(action) {
    try {
        const questionMetadataRes = yield call(
            api.getQuestionMetadata,
            action.payload
        );

        if (questionMetadataRes.errStatus) {
            yield put({
                type: type.GET_QUESTION_METADATA_ERROR,
                status: questionMetadataRes.errStatus,
            });
        } else {
            yield put({
                type: type.GET_QUESTION_METADATA_SUCCESS,
                questionMetadata: questionMetadataRes,
            });
        }
    } catch (e) {
        yield put({
            type: type.GET_QUESTION_METADATA_ERROR,
            message: e.message,
        });
    }
}

function* reviewQuestion(action) {
    try {
        const reviewQuestionaRes = yield call(
            api.reviewQuestion,
            action.payload
        );

        if (reviewQuestionaRes.errStatus) {
            yield put({
                type: type.GET_QUESTION_REVIEW_ERROR,
                status: reviewQuestionaRes.errStatus,
            });
        } else {
            yield put({
                type: type.GET_QUESTION_REVIEW_SUCCESS,
                reviewQuestion: reviewQuestionaRes,
            });
        }
    } catch (e) {
        yield put({
            type: type.GET_QUESTION_REVIEW_ERROR,
            message: e.message,
        });
    }
}

function* getImage(action) {
    try {
        const imageRes = yield call(
            api.getImage,
            action.payload
        );

        if (imageRes.errStatus) {
            yield put({
                type: type.GET_IMAGE_ERROR,
                status: imageRes.errStatus,
            });
        } else {
            yield put({
                type: type.GET_IMAGE_SUCCESS,
                image: { [action.payload]: imageRes?.imgData }
            });
        }
    } catch (e) {
        yield put({
            type: type.GET_IMAGE_ERROR,
            message: e.message,
        });
    }
}

function* getQuesFeedback(action) {
    try {
        const quesFeedbackRes = yield call(
            api.getQuesFeedback,
            action.payload
        );

        if (quesFeedbackRes.errStatus) {
            yield put({
                type: type.GET_QUES_FEEDBACK_ERROR,
                status: quesFeedbackRes.errStatus,
            });
        } else {
            yield put({
                type: type.GET_QUES_FEEDBACK_SUCCESS,
                quesFeedback: quesFeedbackRes
            });
        }
    } catch (e) {
        yield put({
            type: type.GET_QUES_FEEDBACK_ERROR,
            message: e.message,
        });
    }
}

function* setQuesFeedback(action) {
    try {
        const quesFeedbackRes = yield call(
            api.setQuesFeedback,
            action.payload
        );

        if (quesFeedbackRes.errMsg) {
            yield put({
                type: type.SET_QUES_FEEDBACK_ERROR,
                status: quesFeedbackRes.errMsg,
            });
        } else {
            yield put({
                type: type.SET_QUES_FEEDBACK_SUCCESS,
                quesFeedback: quesFeedbackRes
            });
        }
    } catch (e) {
        yield put({
            type: type.SET_QUES_FEEDBACK_ERROR,
            message: e.message,
        });
    }
}

function* setQuesCount(action) {
    try {
        const quesCntRes = yield call(
            api.getQuestionCount,
            action.payload
        );

        if (quesCntRes.errStatus) {
            yield put({
                type: type.SET_QUES_COUNT_ERROR,
                status: quesCntRes.errStatus,
            });
        } else {
            yield put({
                type: type.SET_QUES_COUNT_SUCCESS,
                quesCount: quesCntRes
            });
        }
    } catch (e) {
        yield put({
            type: type.SET_QUES_COUNT_ERROR,
            message: e.message,
        });
    }
}

function* getQuesTutorFeedback(action) {
    try {
        const quesFeedbackRes = yield call(
            api.getQuesTutorFeedback,
            action.payload
        );

        if (quesFeedbackRes.errStatus) {
            yield put({
                type: type.GET_QUES_TUTOR_FEEDBACK_ERROR,
                status: quesFeedbackRes.errStatus,
            });
        } else {
            yield put({
                type: type.GET_QUES_TUTOR_FEEDBACK_SUCCESS,
                quesTutorFeedback: quesFeedbackRes
            });
        }
    } catch (e) {
        yield put({
            type: type.GET_QUES_TUTOR_FEEDBACK_ERROR,
            message: e.message,
        });
    }
}

function* setQuesTutorFeedback(action) {
    try {
        const quesFeedbackRes = yield call(
            api.setQuesTutorFeedback,
            action.payload
        );

        if (quesFeedbackRes.errMsg) {
            yield put({
                type: type.SET_QUES_TUTOR_FEEDBACK_ERROR,
                status: quesFeedbackRes.errMsg
            });
        } else {
            yield put({
                type: type.SET_QUES_TUTOR_FEEDBACK_SUCCESS,
                quesTutorFeedback: quesFeedbackRes
            });
        }
    } catch (e) {
        yield put({
            type: type.SET_QUES_TUTOR_FEEDBACK_ERROR,
            message: e.message,
        });
    }
}

function* createTutorFeedbackBlock(action) {
    try {
        const createBlock = yield call(
            api.createTutorFeedbackBlock,
            action.payload
        );

        if (createBlock.errStatus) {
            yield put({
                type: type.CREATE_TUTOR_FEEDBACK_BLOCK_ERROR,
                status: createBlock.errStatus,
            });
        } else {
            yield put({
                type: type.CREATE_TUTOR_FEEDBACK_BLOCK_SUCCESS,
                createBlock
            });
        }
    } catch (e) {
        yield put({
            type: type.CREATE_TUTOR_FEEDBACK_BLOCK_ERROR,
            message: e.message,
        });
    }
}

function* endTutorFeedbackBlock(action) {
    try {
        const endBlock = yield call(
            api.endTutorFeedbackBlock,
            action.payload
        );

        if (endBlock.errStatus) {
            yield put({
                type: type.END_TUTOR_FEEDBACK_BLOCK_ERROR,
                status: endBlock.errStatus,
            });
        } else {
            yield put({
                type: type.END_TUTOR_FEEDBACK_BLOCK_SUCCESS,
                endBlock
            });
        }
    } catch (e) {
        yield put({
            type: type.END_TUTOR_FEEDBACK_BLOCK_ERROR,
            message: e.message,
        });
    }
}

function* getCategoryPreferences(action) {
    try {
        const getCategoryPreferencesRes = yield call(
            api.getCategoryPreferences,
            action.payload
        );

        if (getCategoryPreferencesRes.errStatus) {
            yield put({
                type: type.GET_CATEGORY_PREFERENCE_ERROR,
                status: getCategoryPreferencesRes.errStatus,
            });
        } else {
            yield put({
                type: type.GET_CATEGORY_PREFERENCE_SUCCESS,
                getCategoryPreferences: getCategoryPreferencesRes
            });
        }
    } catch (e) {
        yield put({
            type: type.GET_CATEGORY_PREFERENCE_ERROR,
            message: e.message,
        });
    }
}

function* setCategoryPreferences(action) {
    try {
        const setCategoryPreferencesRes = yield call(
            api.saveCategoryPreferences,
            action.payload
        );

        if (setCategoryPreferencesRes.errStatus) {
            yield put({
                type: type.SET_CATEGORY_PREFERENCE_ERROR,
                status: setCategoryPreferencesRes.errStatus,
            });
        } else {
            yield put({
                type: type.SET_CATEGORY_PREFERENCE_SUCCESS,
                setCategoryPreferences: setCategoryPreferencesRes
            });
        }
    } catch (e) {
        yield put({
            type: type.SET_CATEGORY_PREFERENCE_ERROR,
            message: e.message,
        });
    }
}

function* watchGetCategories() {
    yield takeEvery(type.GET_CATEGORIES_REQUEST, getCategories);
}

function* watchGetQuesCategories() {
    yield takeEvery(type.GET_QUES_CATEGORIES_REQUEST, getQuesCategories);
}

function* watchCreateQuiz() {
    yield takeEvery(type.CREATE_QUIZ_REQUEST, createQuiz);
}

function* watchSubmitQuiz() {
    yield takeEvery(type.SUBMIT_QUIZ_REQUEST, submitQuiz);
}

function* watchGetQuiz() {
    yield takeEvery(type.GET_QUIZ_REQUEST, getQuiz);
}

function* watchQuestionHistory() {
    yield takeEvery(type.GET_QUESTION_HISTORY_REQUEST, questionHistory);
}

function* watchQuizHistory() {
    yield takeEvery(type.GET_QUIZ_HISTORY_REQUEST, quizHistory);
}

function* watchStudentQuizHistory() {
    yield takeEvery(type.GET_STUDENT_QUIZ_HISTORY_REQUEST, studentQuizHistory);
}

function* watchOverallQuizHistory() {
    yield takeEvery(type.GET_OVERALL_QUIZ_HISTORY_REQUEST, overallQuizHistory);
}

function* watchOverallStudentQuiz() {
    yield takeEvery(type.GET_OVERALL_STUDENT_QUIZ_HISTORY_REQUEST, getOverallStudentQuiz);
}

function* watchQuestionMetadata() {
    yield takeEvery(type.GET_QUESTION_METADATA_REQUEST, getQuestionMetadata);
}

function* watchGetImage() {
    yield takeEvery(type.GET_IMAGE_REQUEST, getImage);
}

function* watchGetQuesFeedback() {
    yield takeEvery(type.GET_QUES_FEEDBACK_REQUEST, getQuesFeedback);
}


function* watchReviewQuestion() {
    yield takeEvery(type.GET_QUESTION_REVIEW_REQUEST, reviewQuestion);
}

function* watchSetQuesFeedback() {
    yield takeEvery(type.SET_QUES_FEEDBACK_REQUEST, setQuesFeedback);
}

function* watchSetQuesCount() {
    yield takeEvery(type.SET_QUES_COUNT_REQUEST, setQuesCount);
}

function* watchGetQuesTutorFeedback() {
    yield takeEvery(type.GET_QUES_TUTOR_FEEDBACK_REQUEST, getQuesTutorFeedback);
}

function* watchSetQuesTutorFeedback() {
    yield takeEvery(type.SET_QUES_TUTOR_FEEDBACK_REQUEST, setQuesTutorFeedback);
}

function* watchCreateTutorFeedbackBlock() {
    yield takeEvery(type.CREATE_TUTOR_FEEDBACK_BLOCK_REQUEST, createTutorFeedbackBlock);
}

function* watchEndTutorFeedbackBlock() {
    yield takeEvery(type.END_TUTOR_FEEDBACK_BLOCK_REQUEST, endTutorFeedbackBlock);
}

function* watchGetCategoryPreferences() {
    yield takeEvery(type.GET_CATEGORY_PREFERENCE_REQUEST, getCategoryPreferences);
}

function* watchSetCategoryPreferences() {
    yield takeEvery(type.SET_CATEGORY_PREFERENCE_REQUEST, setCategoryPreferences);
}

export default function* quizSaga() {
    yield all([
        fork(watchCreateQuiz),
        fork(watchGetQuiz),
        fork(watchSubmitQuiz),
        fork(watchQuizHistory),
        fork(watchGetCategories),
        fork(watchGetQuesCategories),
        fork(watchQuestionHistory),
        fork(watchStudentQuizHistory),
        fork(watchOverallQuizHistory),
        fork(watchOverallStudentQuiz),
        fork(watchQuestionMetadata),
        fork(watchReviewQuestion),
        fork(watchGetImage),
        fork(watchGetQuesFeedback),
        fork(watchSetQuesFeedback),
        fork(watchSetQuesCount),
        fork(watchGetQuesTutorFeedback),
        fork(watchSetQuesTutorFeedback),
        fork(watchCreateTutorFeedbackBlock),
        fork(watchEndTutorFeedbackBlock),
        fork(watchGetCategoryPreferences),
        fork(watchSetCategoryPreferences),
    ]);
}