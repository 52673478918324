import { useState, Fragment } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import "./categories.scss";

export default function Categories(props) {
  const {
    onDisciplineCatSelect,
    onOrgSysCatSelect,
    onExamTypeCatSelect,
    selectedDiscipline,
    selectedOrgSystem,
    selectedExamType,
    totalDiscipline,
    totalOrgSystem,
    examType,
    isViewDisabled
  } = props;

  const [expanded, setExpanded] = useState("discipline");

  const handleAccordionToggle = (panel) => (event, newExpanded) => {
    event.stopPropagation();

    if (event.target.nodeName.toLowerCase() !== "input") {
      setExpanded(newExpanded ? panel : false);
    }
  };

  const selectCategory = (evt, item, type) => {
    evt.stopPropagation();
    const isChecked = evt.target.checked;
    const targetCategory =
      type === "discipline" ? selectedDiscipline : type === 'organSystem' ?
        selectedOrgSystem : selectedExamType;

    if (isChecked) {
      targetCategory.push(item);
    } else {
      for (let i = 0; i < targetCategory.length; i++) {
        if (targetCategory[i] === item) {
          targetCategory.splice(i, 1);
        }
      }
    }

    if (type === "discipline") {
      onDisciplineCatSelect(targetCategory);
    } else if (type === "organSystem") {
      onOrgSysCatSelect(targetCategory);
    } else {
      onExamTypeCatSelect(targetCategory);
    }
  };

  const selectAll = (evt, type) => {
    evt.stopPropagation();
    const isChecked = evt.target.checked;

    if (isChecked) {
      if (type === "discipline") {
        onDisciplineCatSelect(totalDiscipline);
      } else if (type === "organSystem") {
        onOrgSysCatSelect(totalOrgSystem);
      } else {
        onExamTypeCatSelect(examType);
      }
    } else {
      if (type === "discipline") {
        onDisciplineCatSelect([]);
      } else if (type === "organSystem") {
        onOrgSysCatSelect([]);
      } else {
        onExamTypeCatSelect([]);
      }
    }
  };

  const setChecked = (item, type) => {
    const targetCategory =
      type === "discipline" ? selectedDiscipline : type === 'organSystem' ?
        selectedOrgSystem : selectedExamType;

    for (let i = 0; i < targetCategory.length; i++) {
      if (item === targetCategory[i]) {
        return true;
      }
    }

    return false;
  };

  return (
    <section className="categories">
      <Accordion
        square
        expanded={expanded === "discipline"}
        onChange={handleAccordionToggle("discipline")}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          className="categories-summary"
        >
          <Checkbox
            onChange={(evt) => selectAll(evt, "discipline")}
            checked={totalDiscipline.length === selectedDiscipline.length}
            className="select-all-checkbox"
            name="discipline"
            disabled={isViewDisabled}
            color="primary"
          />
          <Typography className="cat-heading">Discipline</Typography>
        </AccordionSummary>
        {totalDiscipline?.sort((a, b) => a.localeCompare(b)).map((item, index) => {
          return (
            <Fragment key={index}>
              <AccordionDetails>
                {
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(evt) =>
                            selectCategory(evt, item, "discipline")
                          }
                          checked={setChecked(item, "discipline")}
                          name={item}
                          disabled={isViewDisabled}
                          color="primary"
                        />
                      }
                      // label={`${item} [${categoryQues[item]?.selected.length}]`}
                      label={item}
                    />
                  </FormGroup>
                }
              </AccordionDetails>
            </Fragment>
          );
        })}
      </Accordion>
      <Accordion
        square
        expanded={expanded === "organSystem"}
        onChange={handleAccordionToggle("organSystem")}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          className="categories-summary"
        >
          <Checkbox
            onChange={(evt) => selectAll(evt, "organSystem")}
            checked={totalOrgSystem.length === selectedOrgSystem.length}
            name="organSystem"
            disabled={isViewDisabled}
            color="primary"
          />
          <Typography className="cat-heading">Organ System</Typography>
        </AccordionSummary>
        {totalOrgSystem?.sort((a, b) => a.localeCompare(b)).map((item, index) => {
          return (
            <Fragment key={index}>
              <AccordionDetails key={index}>
                {
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(evt) =>
                            selectCategory(evt, item, "organSystem")
                          }
                          checked={setChecked(item, "organSystem")}
                          name={item}
                          disabled={isViewDisabled}
                          color="primary"
                        />
                      }
                      // label={`${item} [${categoryQues[item]?.selected.length}]`}
                      label={item}
                    />
                  </FormGroup>
                }
              </AccordionDetails>
            </Fragment>
          );
        })}
      </Accordion>
      <Accordion
        square
        expanded={expanded === "examType"}
        onChange={handleAccordionToggle("examType")}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          className="categories-summary"
        >
          <Checkbox
            onChange={(evt) => selectAll(evt, "examType")}
            checked={examType?.length === selectedExamType?.length}
            name="examType"
            disabled={isViewDisabled}
            color="primary"
          />
          <Typography className="cat-heading">Exam Type</Typography>
        </AccordionSummary>
        {examType?.sort((a, b) => a.localeCompare(b)).map((item, index) => {
          return (
            <Fragment key={index}>
              <AccordionDetails key={index}>
                {
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(evt) =>
                            selectCategory(evt, item, "examType")
                          }
                          checked={setChecked(item, "examType")}
                          name={item}
                          disabled={isViewDisabled}
                          color="primary"
                        />
                      }
                      // label={`${item} [${categoryQues[item]?.selected.length}]`}
                      label={item}
                    />
                  </FormGroup>
                }
              </AccordionDetails>
            </Fragment>
          );
        })}
      </Accordion>
    </section>
  );
}
