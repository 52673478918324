import type from "./types";

export function addUser(userData) {
    return {
        type: type.ADD_USER_REQUEST,
        payload: userData,
    };
}
export function resetAddUser() {
    return {
        type: type.ADD_USER_RESET,
    };
}

export function login(userData) {
    return {
        type: type.LOGIN_USER_REQUEST,
        payload: userData,
    };
}

export function resetLogin() {
    return {
        type: type.LOGIN_USER_RESET,
    };
}

export function userDetails(userId) {
    return {
        type: type.USER_DETAILS_REQUEST,
        payload: userId,
    };
}

export function userDetailsFromToken() {
    return {
        type: type.USER_DETAILS_FROM_TOKEN_REQUEST
    };
}

export function createQuiz(quizData) {
    return {
        type: type.CREATE_QUIZ_REQUEST,
        payload: quizData,
    };
}

export function getQuiz(quizId) {
    return {
        type: type.GET_QUIZ_REQUEST,
        payload: quizId,
    };
}

export function getCategories() {
    return {
        type: type.GET_CATEGORIES_REQUEST,
    };
}

export function getQuesCategories() {
    return {
        type: type.GET_QUES_CATEGORIES_REQUEST,
    };
}

export function submitQuiz(quizData) {
    return {
        type: type.SUBMIT_QUIZ_REQUEST,
        payload: quizData,
    };
}

export function resetSubmitQuiz() {
    return {
        type: type.SUBMIT_QUIZ_RESET
    };
}

export function resetQuiz() {
    return {
        type: type.GET_QUIZ_RESET
    };
}

export function resetCreateQuiz() {
    return {
        type: type.CREATE_QUIZ_RESET
    };
}

export function resetQuestionHistory() {
    return {
        type: type.GET_QUESTION_HISTORY_RESET
    };
}

export function getQuestionHistory(quizId) {
    return {
        type: type.GET_QUESTION_HISTORY_REQUEST,
        payload: quizId,
    };
}

export function getQuizHistory(quizId) {
    return {
        type: type.GET_QUIZ_HISTORY_REQUEST,
        payload: quizId,
    };
}

export function getStudentQuizHistory(quizId) {
    return {
        type: type.GET_STUDENT_QUIZ_HISTORY_REQUEST,
        payload: quizId,
    };
}

export function getOverallQuizHistory(quizId) {
    return {
        type: type.GET_OVERALL_QUIZ_HISTORY_REQUEST,
        payload: quizId,
    };
}

export function getOverallStudentQuizHistory(params) {
    return {
        type: type.GET_OVERALL_STUDENT_QUIZ_HISTORY_REQUEST,
        payload: params,
    };
}

export function resetQuestionMetadata() {
    return {
        type: type.GET_QUESTION_METADATA_RESET
    };
}

export function getQuestionMetadata(questionList) {
    return {
        type: type.GET_QUESTION_METADATA_REQUEST,
        payload: questionList,
    };
}

export function reviewQuestion(questionId) {
    return {
        type: type.GET_QUESTION_REVIEW_REQUEST,
        payload: questionId,
    };
}

// export function getPackageList() {
//     return {
//         type: type.GET_PACKAGE_REQUEST
//     };
// }

export function getUserPackage(payload) {
    return {
        type: type.GET_USER_PACKAGE_REQUEST,
        payload
    };
}

export function createPaymentSession(payload) {
    return {
        type: type.GET_PAYMENT_SESSION_REQUEST,
        payload
    };
}
export function resetPaymentSession() {
    return {
        type: type.RESET_PAYMENT_SESSION_REQUEST
    };
}

export function updatePaymentStatus(payload) {
    return {
        type: type.GET_PAYMENT_STATUS_REQUEST,
        payload
    };
}

export function setUserActivity(payload) {
    return {
        type: type.SET_USER_ACTIVITY_REQUEST,
        payload
    };
}

export function getUserActivity(payload) {
    return {
        type: type.GET_USER_ACTIVITY_REQUEST,
        payload
    };
}
export function getUserPayments(payload) {
    return {
        type: type.GET_USER_PAYMENT_REQUEST,
        payload
    };
}
export function cancelSubscription(payload) {
    return {
        type: type.CANCEL_SUBSCRIPTION_REQUEST,
        payload
    };
}
export function resetCancelSubscription() {
    return {
        type: type.CANCEL_SUBSCRIPTION_RESET
    };
}
export function getInvitations() {
    return {
        type: type.GET_INVITATION_REQUEST
    };
}
export function sendInvitation(payload) {
    return {
        type: type.SEND_INVITATION_REQUEST,
        payload
    };
}
export function getPreference(payload) {
    return {
        type: type.GET_PREFERENCE_REQUEST,
        payload
    };
}
export function setPreference(payload) {
    return {
        type: type.SET_PREFERENCE_REQUEST,
        payload
    };
}
export function resetSendInvitation() {
    return {
        type: type.SEND_INVITATION_RESET
    };
}
export function resendInvitation(payload) {
    return {
        type: type.RESEND_INVITATION_REQUEST,
        payload
    };
}
export function resetResendInvitation() {
    return {
        type: type.RESEND_INVITATION_RESET
    };
}
export function getComment(payload) {
    return {
        type: type.GET_COMMENT_REQUEST,
        payload
    };
}
export function getImage(payload) {
    return {
        type: type.GET_IMAGE_REQUEST,
        payload
    };
}
export function setComment(payload) {
    return {
        type: type.SET_COMMENT_REQUEST,
        payload
    };
}
export function setFeedback(payload) {
    return {
        type: type.SET_FEEDBACK_REQUEST,
        payload
    };
}
export function getQuesFeedback(payload) {
    return {
        type: type.GET_QUES_FEEDBACK_REQUEST,
        payload
    };
}
export function setQuesFeedback(payload) {
    return {
        type: type.SET_QUES_FEEDBACK_REQUEST,
        payload
    };
}
export function resetQuesFeedback() {
    return {
        type: type.SET_QUES_FEEDBACK_RESET
    };
}
export function resetQuesCount() {
    return {
        type: type.SET_QUES_COUNT_RESET
    };
}
export function getQuesCount(payload) {
    return {
        type: type.SET_QUES_COUNT_REQUEST,
        payload
    };
}
export function getQuesTutorFeedback(payload) {
    return {
        type: type.GET_QUES_TUTOR_FEEDBACK_REQUEST,
        payload
    };
}
export function setQuesTutorFeedback(payload) {
    return {
        type: type.SET_QUES_TUTOR_FEEDBACK_REQUEST,
        payload
    };
}
export function resetQuesTutorFeedback() {
    return {
        type: type.SET_QUES_TUTOR_FEEDBACK_RESET
    };
}
export function getTutorFeedbackAll() {
    return {
        type: type.GET_TUTOR_FEEDBACK_ALL_REQUEST
    };
}
export function getStudentFeedbackAll() {
    return {
        type: type.GET_STUDENT_FEEDBACK_ALL_REQUEST
    };
}
export function menuOpenStatus(payload) {
    return {
        type: 'OPEN',
        ...payload
    };
}
export function createTutorFeedbackBlock(payload) {
    return {
        type: type.CREATE_TUTOR_FEEDBACK_BLOCK_REQUEST,
        payload
    };
}
export function resetCreateTutorFeedbackBlock() {
    return {
        type: type.CREATE_TUTOR_FEEDBACK_BLOCK_RESET
    };
}
export function endTutorFeedbackBlock(payload) {
    return {
        type: type.END_TUTOR_FEEDBACK_BLOCK_REQUEST,
        payload
    };
}
export function getAllPlans() {
    return {
        type: type.GET_ALL_PLANS_REQUEST
    };
}
export function resetCreatePlan() {
    return {
        type: type.CREATE_PLAN_RESET
    };
}
export function createPlan(payload) {
    return {
        type: type.CREATE_PLAN_REQUEST,
        payload
    };
}
export function resetUpdatePlan() {
    return {
        type: type.UPDATE_PLAN_RESET
    };
}
export function updatePlan(payload) {
    return {
        type: type.UPDATE_PLAN_REQUEST,
        payload
    };
}
export function resetDeletePlan() {
    return {
        type: type.DELETE_PLAN_RESET
    };
}
export function deletePlan(payload) {
    return {
        type: type.DELETE_PLAN_REQUEST,
        payload
    };
}
export function selectPlan(payload) {
    return {
        type: type.SELECT_PLAN_REQUEST,
        payload
    };
}
export function getActivePlans(payload) {
    return {
        type: type.GET_ACTIVE_PLANS_REQUEST,
        payload
    };
}
export function sendInvites(payload) {
    return {
        type: type.SEND_INVITES_REQUEST,
        payload
    };
}
export function resetSendInvites() {
    return {
        type: type.SEND_INVITES_RESET
    };
}
export function getUsersByRole() {
    return {
        type: type.GET_USERS_BY_ROLE_REQUEST
    };
}
export function getPaymentsByUser(payload) {
    return {
        type: type.GET_PAYMENTS_BY_USER_REQUEST,
        payload
    };
}
export function refundBooking(payload) {
    return {
        type: type.SET_REFUND_BOOKING_REQUEST,
        payload
    };
}
export function resetRefundBooking() {
    return {
        type: type.SET_REFUND_BOOKING_RESET
    };
}
export function getCategoryPreferences(payload) {
    return {
        type: type.GET_CATEGORY_PREFERENCE_REQUEST,
        payload
    };
}
export function setCategoryPreferences(payload) {
    return {
        type: type.SET_CATEGORY_PREFERENCE_REQUEST,
        payload
    };
}