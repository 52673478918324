let domain = "https://qlearnapi.medlearnity.com";

const storedValue = JSON.parse(window.localStorage.getItem('emailLogin'));

if (storedValue === undefined) {
    window.localStorage.setItem('emailLogin', false);
}

let isProdEnv = storedValue !== undefined ? !storedValue : true;

if (window.location.hostname === 'devqlearn.medlearnity.com' || window.location.hostname === 'localhost') {
    domain = "https://devqlearnapi.medlearnity.com";
    isProdEnv = false;
} else if (window.location.hostname === 'preprodqlearn.medlearnity.com') {
    domain = "https://preprodqlearnapi.medlearnity.com";
    isProdEnv = false;
}

export const isProd = isProdEnv;

const urls = {
    domain,
    addUser: `${domain}/qlearn/auth/signup`,
    login: `${domain}/qlearn/auth/signin`,
    verifyAccount: `${domain}/qlearn/auth/verify-account/`,
    forgetPwd: `${domain}/qlearn/auth/forget-password/`,
    changePwd: `${domain}/qlearn/auth/change-password/`,
    userDetails: `${domain}/qlearn/user/`,
    userDetailsFromToken: `${domain}/qlearn/user/me`,
    questionsList: `${domain}/qlearn/user/`,
    submitQuiz: `${domain}/qlearn/quiz`,
    createQuiz: `${domain}/qlearn/api/v2/quiz`,
    categories: `${domain}/qlearn/quiz/categories`,
    questionCategories: `${domain}/qlearn/bank/question/category`,
    getQuiz: `${domain}/qlearn/quiz/`,
    quizHistory: `${domain}/qlearn/quiz-history/quiz/`,
    questionHistory: `${domain}/qlearn/question-history/quiz/`,
    studentQuizHistory: `${domain}/qlearn/quiz-history/user/`,
    overallStudentQuiz: `${domain}/qlearn/quiz/user`,
    overallQuizHistory: `${domain}/qlearn/user-performance/user/`,
    questionMetadata: `${domain}/qlearn/bank/question`,
    reviewQuestion: `${domain}/qlearn/question-history`,
    packageList: `${domain}/qlearn/package/status/ACTIVE`,
    paymentSession: `${domain}/qlearn/booking/create-payment-session`,
    booking: `${domain}/qlearn/booking`,
    userActivity: `${domain}/qlearn/useractivity/user/`,
    userPayments: `${domain}/qlearn/booking/user/`,
    cancelSubscription: 'cancel-subscription',
    invitation: `${domain}/qlearn/enrollments/invitations`,
    preference: `${domain}/qlearn/user/preference`,
    comment: `${domain}/qlearn/bank/question`,
    feedback: `${domain}/qlearn/user/feedback`,
    image: `${domain}/qlearn/bank/image`,
    quesFeedback: `${domain}/qlearn/question/feedback/student`,
    quesCatCount: `${domain}/qlearn/quiz/categories/questions`,
    quesTutorFeedback: `${domain}/qlearn/question/feedback/tutor`,
    tutorFeedbackAll: `${domain}/qlearn/question/feedback/tutor/all`,
    studentFeedbackAll: `${domain}/qlearn/question/feedback/student/all`,
    feedbackBlock: `${domain}/qlearn/quiz/block`,
    packagePlan: `${domain}/qlearn/plan`,
    sendInvites: `${domain}/qlearn/enrollments/invitations`,
    getUsersByRole: `${domain}/qlearn/users`,
    categoryPreference: `${domain}/qlearn/quiz/categories/preferences/selected/user/`,
}

export default urls;
